import { memo, useState, useRef, useEffect, useCallback } from 'react';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';

import {
  Box,
  Link as MUILink,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { useTracking } from '@hooks/tracking';

import { HiddenSm } from '@components/ui';
import Router from 'next/router';
import Card, { ICardProps } from '../CardContent';

import {
  Container,
  ContainerHeader,
  Content,
  Title,
  LinkGroup,
  LinkGroupAll,
  WebSerieDescription,
  ContainerBody,
  Description,
  WebSerieTitle,
  ContainerTitle,
  WebSerieSeasonNumber,
  Img,
  Link,
} from './styles';

const Arrows = dynamic(() => import('./ArrowsControl'));

export interface SliderProps {
  contents: ICardProps[];
  WebSerie: boolean;
  title?: string;
  link?: any;
  variant?: 'horizontal' | 'vertical';
  onClick?(e: Event): void;
  onClickFavorite?: (contendId: string) => void;
  showModalConfirm?: boolean;
  className?: string;
  description?: string;
  urlImageSeason?: string;
  titleWebSerie?: string;
  customArrowCLick?: (event: 'next' | 'prev') => void;
  WebSerieSeasonNumberHome?: number;
  seasonFeatured?: string;
  slug?: string;
  viewSeason?: boolean;
  fontBold?: boolean;
}

const Slider: React.FC<SliderProps> = ({
  contents,
  title,
  link,
  variant = 'vertical',
  onClick,
  onClickFavorite,
  showModalConfirm,
  className,
  WebSerie: webSerie,
  description,
  urlImageSeason,
  titleWebSerie,
  customArrowCLick,
  WebSerieSeasonNumberHome,
  seasonFeatured,
  viewSeason,
  slug,
  fontBold,
}) => {
  const { track } = useTracking();
  const perPage = 4;
  const refContent = useRef<HTMLDivElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [page, setPage] = useState(1);
  const [contentsPage, setContentsPage] = useState(contents?.slice(0, 1));
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down('sm'));
  const [countScroll, setCountScroll] = useState(0);

  useEffect(() => {
    if (refContainer.current && contents?.length > 0) {
      setContentsPage(contents.slice(0, Math.round(containerWidth / 140 + 1)));
    }
  }, [contents, containerWidth]);

  const handleClientWidth = useCallback(() => {
    setContainerWidth(refContainer.current?.clientWidth);
  }, []);

  const handleTrackWebserieDescriptionClick = useCallback(() => {
    track('Clicked at webSeries Description', {
      title,
    });
  }, [track, title]);

  useEffect(() => {
    handleClientWidth();
    window.addEventListener('resize', handleClientWidth, {
      passive: true,
    });
    return () => {
      window.removeEventListener('resize', handleClientWidth);
    };
    // eslint-disable-next-line
  }, []);

  if (!contents || contents.length < 1) return null;
  const handleScroll = (event: 'next' | 'prev') => {
    if (refContent.current && refContainer.current) {
      const { scrollLeft } = refContent.current;
      const clientWidth = refContainer.current.clientWidth - 100;
      const valueScroll = event === 'next' ? clientWidth : -clientWidth;

      refContent.current.scrollTo({
        left: scrollLeft + valueScroll,
        behavior: 'smooth',
      });
    }
  };

  const handleNextPage = () => {
    if (contentsPage.length < contents.length) {
      setPage(page + 1);
      setContentsPage(contents.slice(0, (page + 1) * perPage));
    }
  };

  const handleOnClickViewAll = () => {
    track('Clicked at View All button', {
      title,
    });
  };

  return (
    <>
      <Container
        ref={refContainer}
        className={className}
        isHomeWebSerieFeatured
      >
        <ContainerHeader>
          {title && (
            <Title
              onClick={() => {
                if (customArrowCLick) {
                  customArrowCLick('next');
                  if (countScroll === 0) {
                    setCountScroll(countScroll + 1);
                  }
                }
              }}
            >
              {webSerie ? (
                <Link
                  href={`webseries/${slug}`}
                  onClick={() => handleTrackWebserieDescriptionClick()}
                >
                  {title}
                </Link>
              ) : (
                <>{title}</>
              )}
              {seasonFeatured && (
                <div>
                  <WebSerieSeasonNumber>{seasonFeatured}</WebSerieSeasonNumber>
                </div>
              )}
            </Title>
          )}

          <LinkGroup>
            {link && (
              <NextLink href={link}>
                <LinkGroupAll onClick={handleOnClickViewAll}>
                  Ver todos &#62;
                </LinkGroupAll>
              </NextLink>
            )}
            <HiddenSm>
              <Arrows
                onPressNext={() => {
                  if (customArrowCLick) {
                    setCountScroll(countScroll + 1);
                    customArrowCLick('next');
                    if (countScroll > 0) {
                      handleScroll('next');
                    }
                  } else {
                    handleScroll('next');
                  }
                }}
                onPressPrev={() => {
                  if (customArrowCLick) {
                    setCountScroll(0);
                    customArrowCLick('prev');
                    handleScroll('prev');
                  } else {
                    handleScroll('prev');
                  }
                }}
              />
            </HiddenSm>
          </LinkGroup>
        </ContainerHeader>
        <ContainerBody>
          {webSerie && (
            <WebSerieDescription>
              <Link
                href={`webseries/${slug}`}
                onClick={() => handleTrackWebserieDescriptionClick()}
              >
                <ContainerTitle>
                  {titleWebSerie && (
                    <WebSerieTitle>{titleWebSerie}</WebSerieTitle>
                  )}
                  {WebSerieSeasonNumberHome && (
                    <WebSerieSeasonNumber>
                      Temporada {WebSerieSeasonNumberHome}
                    </WebSerieSeasonNumber>
                  )}
                </ContainerTitle>
                {urlImageSeason && (
                  <Img
                    src={urlImageSeason}
                    width={match ? '300px' : '550px'}
                    height={match ? '180px' : '350px'}
                    objectFit="cover"
                    alt="Img carousel"
                    quality="50"
                  />
                )}
                <Description
                  style={{ fontWeight: fontBold ? 'bold' : 'normal' }}
                >
                  {description}
                </Description>
                {viewSeason && (
                  <div style={{ marginTop: viewSeason ? '20px' : '0px' }}>
                    <MUILink
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        Router.push(`webseries/${slug}`);
                        track('Clicked at View All webSeries', {
                          titleWebSerie,
                        });
                      }}
                    >
                      Ver todas as temporadas {'>'}
                    </MUILink>
                  </div>
                )}
              </Link>
            </WebSerieDescription>
          )}
          <Content
            vertical
            horizontal
            hideScrollbars
            innerRef={refContent}
            onClick={onClick ? e => onClick(e) : null}
            onScroll={() => handleNextPage()}
            nativeMobileScroll
            style={{ marginLeft: webSerie ? '-10px' : '0px' }}
          >
            {contentsPage?.map(content => (
              <Card
                webserieStyle={!!titleWebSerie}
                key={content.id}
                {...content}
                variant={variant}
                onClickFavorite={onClickFavorite}
                showModalConfirm={showModalConfirm}
              />
            ))}
            <Box minWidth="10px" height="1px" />
          </Content>
        </ContainerBody>
      </Container>
    </>
  );
};

export default memo(Slider);
