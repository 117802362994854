import { styled, Theme } from '@material-ui/core';
import { calcSizeBasedInVhScale } from '@utils/metrics';
import ScrollContainer from 'react-indiana-drag-scroll';
import Image from 'next/image';

export const Content = styled(ScrollContainer)(({ theme }) => ({
  display: 'flex',

  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  scrollBehavior: 'smooth',

  [theme.breakpoints.down('sm')]: {
    '& > div:first-child': {
      marginLeft: '40px',
    },
  },
}));

interface IContainer {
  theme: Theme;
  isHomeWebSerieFeatured?: boolean;
}

interface IDescription {
  theme: Theme;
  featured?: boolean;
}

export const Container = styled('div')(
  ({ theme, isHomeWebSerieFeatured }: IContainer) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0 40px 40px',
    borderRadius: '13px 0 0 13px',
    marginLeft: 40,
    backgroundColor: '#ffffff',
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.11)',
    marginTop: 25,
    opacity: isHomeWebSerieFeatured ? 0.9 : 1,

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      boxShadow: 'none',
      padding: '20px 0px',
      marginTop: 16,
    },
  }),
);

export const ContainerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 16,
  paddingBottom: 32,
  width: '98%',
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    marginLeft: 20,
    paddingTop: 0,
    paddingBottom: 18,
  },
}));

export const ContainerBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const Box = styled('div')(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    marginLeft: 20,
  },
}));

export const Title = styled('h1')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  fontFamily: 'Barlow',
  fontSize: 28,
  fontWeight: 'bold',
  color: '#000000',
  margin: 0,

  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    margin: 'initial',
  },
}));

export const LinkGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',

  a: {
    color: '#0080ed',
    cursor: 'pointer',
  },
});

export const LinkGroupAll = styled('a')(({ theme }) => ({
  marginRight: 38,
  color: '#0080ed',
  fontSize: 12,
  cursor: 'pointer',

  [theme.breakpoints.up('md')]: {
    fontSize: 18,
  },
}));

export const LinkGroupBackFavorites = styled('a')(({ theme }) => ({
  color: '#0080ed',
  fontSize: 16,
  cursor: 'pointer',
  padding: '0 0 45px 20px',

  [theme.breakpoints.up('md')]: {
    fontSize: 18,
    padding: '0 0 60px 40px',
    marginTop: -35,
  },
}));

export const WebSerieDescription = styled('div')(({ theme }) => ({
  maxWidth: calcSizeBasedInVhScale(600),
  marginRight: '50px',
  [theme.breakpoints.down(480)]: {
    marginRight: '0px',
  },
  [theme.breakpoints.down(1500)]: {
    marginRight: calcSizeBasedInVhScale(100),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '-20px',
    minWidth: '130vw',
    marginRight: '0px',
    paddingLeft: calcSizeBasedInVhScale(30),
    marginBottom: calcSizeBasedInVhScale(30),
  },
}));

export const WebSerieTitle = styled('p')(() => ({
  fontSize: '18px',
  fontWeight: 'bold',
}));

export const WebSerieSeasonNumber = styled('p')(() => ({
  fontSize: '14px',
  fontWeight: 'bold',
  marginLeft: '20px',
  position: 'relative',
}));

export const ContainerTitle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const LeftContainer = styled('div')({
  marginTop: calcSizeBasedInVhScale(17),
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
});

export const Img = styled(Image)({
  borderRadius: '30px',
  paddingRight: '50px',
});

export const Description = styled('p')(({ theme, featured }: IDescription) => ({
  fontSize: '16px',
  marginTop: '25px',
  width: calcSizeBasedInVhScale(473),
  maxWidth: calcSizeBasedInVhScale(473),
  fontWeight: featured ? 'bold' : 'normal',
  [theme.breakpoints.down(1200)]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingRights: '20px',
    marginBottom: '-10px',
    width: calcSizeBasedInVhScale(400),
    maxWidth: calcSizeBasedInVhScale(400),
  },
}));

export const Link = styled('a')(() => ({
  textDecoration: 'none',
  color: 'inherit',
}));
